.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  width: 100%;
}

video {
  border-radius: 16px;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.customSelect {
  .ant-select-selector {
    border: none !important;
    font-family: Nunito !important;
    border-color: rgba(204, 204, 204, 0.5) !important;
    box-shadow: none !important;
    .ant-select-selection-item {
      font-size: 20px !important;
      font-weight: 600 !important;
      color: #153A5C !important;
    }
}
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  color: rgba(21, 58, 92, 1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

/* nunito-200 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 200;
  src: url('../src/fonts/nunito/nunito-v25-latin_latin-ext-200.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* nunito-300 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  src: url('../src/fonts/nunito/nunito-v25-latin_latin-ext-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* nunito-regular - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: url('../src/fonts/nunito/nunito-v25-latin_latin-ext-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* nunito-500 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  src: url('../src/fonts/nunito/nunito-v25-latin_latin-ext-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* nunito-600 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  src: url('../src/fonts/nunito/nunito-v25-latin_latin-ext-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* nunito-700 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  src: url('../src/fonts/nunito/nunito-v25-latin_latin-ext-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* nunito-800 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  src: url('../src/fonts/nunito/nunito-v25-latin_latin-ext-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* nunito-900 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  src: url('../src/fonts/nunito/nunito-v25-latin_latin-ext-900.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
 

.color-scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background : #F2F2F2;
  border-radius: 10px;
}


/* Track */
.color-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}
 
/* Handle */
.color-scroll::-webkit-scrollbar-thumb {
  background: rgb(111	85	243);
  border-radius: 10px;
  width: 8px;
  height: 8px;
}

/* Handle on hover */
.color-scroll::-webkit-scrollbar-thumb:hover {
  background: #8F8F8F; 

}